.radio {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translateY(-50%) rotate(-90deg);
  transform-origin: 0 0;
  z-index: 100; }
  .radio a {
    display: block;
    padding: 8px 40px 10px 24px;
    background: #cc2f29;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.5px;
    border-radius: 0 0 6px 6px;
    transition: 0.4s all;
    position: relative; }
    @media (max-width: 767px) {
      .radio a {
        padding: 6px 32px 6px 16px;
        font-size: 12px; } }
    .radio a:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 8px;
      width: 24px;
      height: 24px;
      background: url(../media/59c2936d855712d5c71f283ce67698f7.svg) no-repeat 50% 50%;
      background-size: contain;
      transform: translateY(calc(-50% - 1px)) rotate(90deg); }
      @media (max-width: 767px) {
        .radio a:after {
          width: 20px;
          height: 20px; } }
    .radio a:hover {
      background: #db524d; }
