.footer {
  position: relative;
  overflow: hidden;
  background: #F9F9F9;
  padding-top: 30px; }
  @media (min-width: 1200px) {
    .footer {
      padding-top: 64px; } }
  .footer__copy {
    text-align: center;
    padding-bottom: 38px;
    color: #bdbcbd;
    font-size: 12px;
    line-height: 120%;
    font-weight: 400;
    flex-direction: column;
    display: flex; }
    @media (min-width: 960px) {
      .footer__copy {
        display: block; } }
    @media (min-width: 1200px) {
      .footer__copy {
        padding-bottom: 3.33333vw;
        font-size: 0.98958vw; } }
    @media (min-width: 1400px) {
      .footer__copy {
        font-size: 0.72917vw; } }
    .footer__copy a {
      text-decoration: none;
      color: inherit; }
      .footer__copy a:hover {
        color: #263228;
        transition: all 0.3s ease-in-out; }
  @media (min-width: 1200px) {
    .footer__copy-left a {
      margin-left: 0.78125vw; } }
  .footer__copy-right {
    color: #bdbcbd;
    position: relative; }
    .footer__copy-right a {
      text-decoration: none;
      color: #bdbcbd; }
    @media (min-width: 960px) {
      .footer__copy-right {
        margin-left: 0.78125vw; } }
  .footer__logo {
    display: block;
    margin-bottom: 32px; }
  .footer__info {
    color: #262261;
    margin-bottom: 32px;
    font-size: 20px;
    font-weight: 700; }
  .footer__socials {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 30px; }
    @media (min-width: 1200px) {
      .footer__socials {
        margin-bottom: 70px; } }
  .footer__social-wrapper {
    display: inline-block;
    transition: all 0.3s ease-in-out; }
    .footer__social-wrapper:hover {
      opacity: 0.6; }
  .footer__social-icon {
    display: block; }
  .footer .hotline {
    color: #262261;
    font-weight: 400;
    margin-bottom: 32px; }
    .footer .hotline__header {
      font-weight: 700;
      font-size: 20px;
      line-height: 140%;
      margin-bottom: 8px; }
    .footer .hotline__content {
      font-size: 18px;
      line-height: 170%; }
      .footer .hotline__content a {
        text-decoration: none;
        color: #262261; }
  .footer__menu-header {
    color: #262261;
    font-weight: 700;
    margin-bottom: 16px;
    font-size: 20px; }
  .footer__copy {
    text-align: center;
    padding: 16px 16px 32px 16px;
    border-top: 1px solid #E8E8E8;
    color: rgba(38, 34, 97, 0.4);
    font-size: 14px;
    line-height: 149%; }
  .footer .footer-menu {
    color: #262261;
    font-weight: 400;
    margin-bottom: 16px; }
    .footer .footer-menu__elem {
      margin-bottom: 12px; }
    .footer .footer-menu__link {
      font-size: 18px;
      line-height: 130%;
      text-decoration: none;
      color: #262261;
      transition: all 0.3s ease-in-out; }
      .footer .footer-menu__link:hover {
        opacity: 0.6; }
  .footer .euinfo {
    padding: 20px 0; }
    .footer .euinfo__content {
      color: rgba(38, 34, 97, 0.4);
      font-size: 14px;
      line-height: 149%; }
