.support-box {
  box-shadow: inset 0 0 0 15px #DBEBFC;
  padding: 34px 32px;
  display: block;
  text-decoration: none;
  margin-bottom: 30px;
  transition: all 0.3s ease-in-out;
  padding-top: 16px; }
  .support-box:hover {
    box-shadow: inset 0 0 0 15px #2495d4; }
  .support-box__title {
    z-index: 1;
    position: relative;
    font-size: 16px;
    color: #262261;
    margin-bottom: 16px;
    text-align: center;
    display: block;
    display: none; }
    @media (min-width: 1200px) {
      .support-box__title {
        font-size: 20px; } }
    .support-box__title span {
      color: #2495d4; }
  .support-box__desc {
    z-index: 1;
    position: relative;
    text-align: center;
    color: #262261;
    font-size: 16px;
    line-height: 130%;
    font-weight: 400;
    display: block; }
    @media (min-width: 1200px) {
      .support-box__desc {
        font-size: 18px; } }
  .support-box__icon-wrapper {
    z-index: 1;
    position: relative;
    margin: 0 auto 16px auto;
    display: block; }
  .support-box__icon {
    margin: 0 auto; }
  .support-box--blue {
    position: relative;
    overflow: hidden;
    box-shadow: none; }
    .support-box--blue:after {
      z-index: -1;
      width: 200%;
      height: 200%;
      position: absolute;
      content: '';
      background: linear-gradient(122.99deg, #1B3A8A 0%, #03A7E8 100%);
      left: 50%;
      top: 50%;
      transition: all 0.3s ease-in-out;
      transform: translate(-50%, -50%); }
    .support-box--blue:hover {
      box-shadow: none; }
      .support-box--blue:hover:after {
        left: 80%;
        top: 80%; }
    .support-box--blue .support-box__title {
      color: #fff; }
      .support-box--blue .support-box__title span {
        color: #fff; }
    .support-box--blue .support-box__desc {
      color: #fff; }
