.logo-slider {
  padding: 30px;
  background: #fff;
  overflow-x: hidden; }
  @media (min-width: 1200px) {
    .logo-slider {
      padding: 64px 30px; } }
  .logo-slider__image {
    margin: 0 auto;
    width: auto !important;
    max-width: 100%; }
  .logo-slider .owl-nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%; }
  .logo-slider .owl-prev, .logo-slider .owl-next {
    cursor: pointer;
    position: absolute;
    border: none;
    outline: none;
    transition: all 0.3s ease-in-out;
    transform: translateY(-50%);
    background: #fff; }
    .logo-slider .owl-prev:hover, .logo-slider .owl-next:hover {
      opacity: 0.6; }
  .logo-slider .owl-prev {
    left: -40px; }
  .logo-slider .owl-next {
    right: -40px; }
