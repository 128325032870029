.contact-form {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  overflow: auto;
  transition: all 0.3s ease-in-out;
  visibility: hidden; }
  .contact-form__content {
    position: relative;
    top: 0;
    right: -110%;
    width: 100%;
    background: #fff;
    padding: 42px;
    height: 100%;
    z-index: 15;
    overflow: auto;
    transition: all 0.3s ease-in-out;
    margin-left: auto; }
    @media (min-width: 768px) {
      .contact-form__content {
        width: 629px; } }
  .contact-form__backdrop {
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.6);
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
    position: absolute; }
  .contact-form__header {
    color: #263228;
    font-size: 24px;
    line-height: 34px;
    margin-bottom: 32px; }
    @media (min-width: 960px) {
      .contact-form__header {
        margin-bottom: 1.66667vw; } }
  .contact-form__last-input {
    margin-bottom: 32px; }
    @media (min-width: 960px) {
      .contact-form__last-input {
        margin-bottom: 1.66667vw; } }
  .contact-form__checkboxes {
    margin-bottom: 32px; }
    @media (min-width: 960px) {
      .contact-form__checkboxes {
        margin-bottom: 1.66667vw; } }
  .contact-form.active {
    visibility: visible; }
    .contact-form.active .contact-form__content {
      right: 0; }
    .contact-form.active .contact-form__backdrop {
      opacity: 1;
      visibility: visible; }
  .contact-form__closer {
    transition: all 0.3s ease-in-out;
    background: #2495D4;
    width: 49px;
    height: 49px;
    position: absolute;
    right: 0;
    top: 0;
    margin-right: 0;
    margin-left: auto;
    cursor: pointer;
    z-index: 1; }
    .contact-form__closer:hover {
      opacity: 0.6; }
    .contact-form__closer:after {
      position: absolute;
      content: url("/images/icons/close.svg");
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 17px; }

.write-us {
  position: fixed;
  right: 0;
  display: none;
  z-index: 7;
  cursor: pointer; }
  @media (min-width: 768px) {
    .write-us {
      display: inline-block;
      top: 425px; } }
