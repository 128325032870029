* {
  font-family: 'Arial', sans-serif; }

.checkbox__wrapper {
  margin-bottom: 16px;
  position: relative;
  padding-right: 21px; }

.checkbox__label {
  height: auto;
  display: inline-block;
  padding-left: 40px;
  top: 3px;
  font-size: 12px;
  cursor: pointer;
  line-height: 149%;
  font-weight: 300;
  transition: all 0.2s linear;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  color: rgba(61, 61, 61, 0.5); }
  @media (min-width: 1200px) {
    .checkbox__label {
      padding-left: 2.08333vw;
      top: 0.15625vw;
      font-size: 0.625vw; } }
  .checkbox__label a {
    color: rgba(61, 61, 61, 0.5);
    text-decoration: underline;
    transition: all 0.3s ease-in-out; }
    .checkbox__label a:hover {
      opacity: 0.6; }
    .checkbox__label a:hover {
      opacity: 0.6; }
  @media (min-width: 768px) {
    .checkbox__label {
      line-height: 120%; } }
  .checkbox__label:before, .checkbox__label:after {
    position: absolute;
    display: block; }
  .checkbox__label:before {
    display: block;
    left: 0;
    top: -6px;
    width: 24px;
    height: 24px;
    overflow: hidden;
    content: '';
    border: 1px solid #3D3D3D; }
    @media (min-width: 1200px) {
      .checkbox__label:before {
        top: -0.3125vw;
        width: 1.25vw;
        height: 1.25vw; } }
  .checkbox__label:after {
    top: -3px;
    left: -15px;
    width: 22px;
    height: 22px;
    border-radius: 1px;
    overflow: hidden;
    content: '';
    background: transparent;
    transition: all 0.2s ease-in-out; }
    @media (min-width: 1200px) {
      .checkbox__label:after {
        top: -0.15625vw;
        left: 0.15625vw;
        width: 1.14583vw;
        height: 1.14583vw; } }
  .checkbox__label:hover {
    opacity: 1; }
  .checkbox__label--full {
    width: 100%;
    flex: 0 0 100%; }
  .checkbox__label--light {
    color: rgba(36, 36, 36, 0.5);
    line-height: 168.78%; }
    .checkbox__label--light:before {
      background: rgba(0, 0, 0, 0.12);
      top: 0.10417vw; }
    .checkbox__label--light:after {
      top: 0.3125vw !important; }

.checkbox__input {
  position: absolute;
  z-index: -1000;
  left: -1000px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }
  .checkbox__input:checked + .checkbox__label:after {
    left: 5px;
    top: -1px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    background: #263228; }
    @media (min-width: 1200px) {
      .checkbox__input:checked + .checkbox__label:after {
        left: 0.20833vw;
        top: -1px;
        width: 0.83333vw;
        height: 0.83333vw; } }

.form-input {
  background: #F9F9F9;
  padding: 16px 32px;
  color: #263228;
  border-radius: 3px;
  margin-bottom: 16px;
  border: none;
  display: block;
  outline: none;
  font-size: 16px;
  width: 100%; }
  .form-input.error {
    border: 2px solid red; }
  .form-input::-webkit-input-placeholder {
    color: #263228;
    opacity: 1; }
  .form-input::-moz-placeholder {
    color: #263228;
    opacity: 1; }
  .form-input:-ms-input-placeholder {
    color: #263228;
    opacity: 1; }
  .form-input::-ms-input-placeholder {
    color: #263228;
    opacity: 1; }
  .form-input::placeholder {
    color: #263228;
    opacity: 1; }
  @media (min-width: 1200px) {
    .form-input {
      padding: 0.83333vw 1.66667vw;
      margin-bottom: 0.83333vw;
      font-size: 0.98958vw; } }
  .form-input--number {
    -webkit-appearance: none;
            appearance: none;
    -moz-appearance: textfield; }
    .form-input--number::-webkit-outer-spin-button, .form-input--number::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; }
  .form-input--textarea {
    resize: none;
    height: 61px;
    transition: all 0.3s ease-in-out; }
    .form-input--textarea:focus {
      height: 150px; }

.btn {
  padding: 16px 32px;
  font-size: 13px;
  line-height: 130%;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  border-radius: 0;
  border: none; }
  @media (min-width: 1200px) {
    .btn {
      font-size: 16px; } }
  .btn.btn-block {
    justify-content: center; }

.btn-primary {
  background: #2495d4;
  color: #fff; }
  .btn-primary:hover, .btn-primary.active {
    background: #262261; }

.btn-secondary {
  background: #fff;
  color: #2495d4; }
  .btn-secondary:hover, .btn-secondary.active {
    background: #262261; }

.btn-tertiary {
  background: #fff;
  color: #262261;
  border: 1px solid #DBDBDB; }
  .btn-tertiary:hover, .btn-tertiary.active {
    color: #fff;
    background: #262261; }

.btn-accept {
  background: #34CA82;
  color: #fff;
  border: none; }
  .btn-accept:hover, .btn-accept.active {
    border: none;
    color: #fff;
    background: #2495d4; }

.btn-outline-primary {
  background-color: #fff;
  box-shadow: inset 0 0 0 2px #2495d4;
  color: #2495d4; }
  .btn-outline-primary:hover {
    color: #2495d4;
    background-color: #fff;
    box-shadow: inset 0 0 0 4px #2495d4; }

.btn-outline-secondary {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #6c757d;
  color: #6c757d; }
  .btn-outline-secondary:hover {
    color: #6c757d;
    background-color: transparent;
    box-shadow: inset 0 0 0 4px #6c757d; }

.btn-outline-danger {
  background-color: transparent;
  box-shadow: inset 0 0 0 2px #dc3545;
  color: #dc3545; }
  .btn-outline-danger:hover {
    color: #dc3545;
    background-color: transparent;
    box-shadow: inset 0 0 0 4px #dc3545; }

.btn-icon {
  margin-right: 10px;
  width: 14px;
  height: 14px; }

.btn-group {
  margin-bottom: 32px;
  border-radius: 5px;
  overflow: hidden; }

.btn-group-lg > .btn, .btn-lg {
  font-size: 16px; }

.btn-sm {
  padding: 12px 24px; }

.page-link {
  position: relative;
  display: block;
  padding: .5rem .75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #262261;
  background-color: #fff;
  border: 1px solid #dee2e6; }

.badge {
  color: #fff; }

.badge-course {
  background: #007BFF; }

.badge-workshop {
  background: #28A745; }

.badge-training {
  background: #FFC107; }

.form__input-wrapper {
  position: relative; }

.form__input-btn {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
  cursor: pointer;
  background: #fff;
  border: none;
  outline: none; }

.form-control {
  border-radius: 0;
  color: #262261;
  margin-bottom: 16px; }
  .form-control::-webkit-input-placeholder {
    color: #262261; }
  .form-control::-moz-placeholder {
    color: #262261; }
  .form-control:-ms-input-placeholder {
    color: #262261; }
  .form-control::-ms-input-placeholder {
    color: #262261; }
  .form-control::placeholder {
    color: #262261; }

.form-check-label {
  font-size: 14px;
  color: #262261; }

.form-avatar label {
  display: block; }

.form-avatar img {
  width: 120px;
  height: auto;
  display: table; }

.img-responsive {
  max-width: 100%;
  display: block; }

.cms-content {
  font-size: 14px;
  line-height: 149%;
  color: #262261;
  font-weight: 300; }
  @media (min-width: 1200px) {
    .cms-content {
      font-size: 1.04167vw; } }
  .cms-content--padding {
    padding: 32px 0 24px 0; }
  .cms-content a:not(.btn) {
    text-decoration: none;
    color: #2495d4;
    transition: all 0.3s ease-in-out; }
    .cms-content a:not(.btn):hover {
      opacity: 0.6; }
  .cms-content p {
    margin-bottom: 25px; }
    @media (min-width: 1200px) {
      .cms-content p {
        margin-bottom: 1.30208vw; } }
  .cms-content strong {
    font-weight: 700; }
  .cms-content h1 {
    font-weight: 400;
    line-height: 140%;
    color: #263228;
    font-size: 44px;
    margin-bottom: 16px; }
    @media (min-width: 960px) {
      .cms-content h1 {
        margin-bottom: 0.83333vw; } }
  .cms-content h2 {
    font-weight: 400;
    line-height: 140%;
    color: #263228;
    font-size: 38px;
    margin-bottom: 16px; }
    @media (min-width: 960px) {
      .cms-content h2 {
        margin-bottom: 0.83333vw; } }
  .cms-content h3 {
    font-weight: 400;
    line-height: 140%;
    color: #263228;
    font-size: 32px;
    margin-bottom: 16px; }
    @media (min-width: 960px) {
      .cms-content h3 {
        margin-bottom: 0.83333vw; } }
  .cms-content h4 {
    font-weight: 400;
    line-height: 140%;
    color: #263228;
    font-size: 26px;
    margin-bottom: 16px; }
    @media (min-width: 960px) {
      .cms-content h4 {
        margin-bottom: 0.83333vw; } }
  .cms-content h5 {
    font-weight: 400;
    line-height: 140%;
    color: #263228;
    font-size: 20px;
    margin-bottom: 16px; }
    @media (min-width: 960px) {
      .cms-content h5 {
        margin-bottom: 0.83333vw; } }
  .cms-content h6 {
    font-weight: 400;
    line-height: 140%;
    color: #263228;
    font-size: 14px;
    margin-bottom: 16px; }
    @media (min-width: 960px) {
      .cms-content h6 {
        margin-bottom: 0.83333vw; } }
  .cms-content ul {
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 32px; }
    @media (min-width: 1200px) {
      .cms-content ul {
        margin-bottom: 1.66667vw; } }
    .cms-content ul li {
      position: relative;
      padding: 0 16px;
      margin-bottom: 16px; }
      .cms-content ul li:before {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        content: '';
        background: #2495d4;
        width: 8px;
        height: 8px;
        border-radius: 50%; }
  .cms-content ol {
    padding-left: 0;
    list-style-type: none;
    counter-reset: list-number;
    margin-bottom: 32px; }
    @media (min-width: 1200px) {
      .cms-content ol {
        margin-bottom: 1.66667vw; } }
    .cms-content ol li {
      counter-increment: list-number;
      position: relative;
      margin-bottom: 24px;
      padding-left: 55px; }
      .cms-content ol li:before {
        content: counter(list-number);
        font-weight: 400;
        font-size: 16px;
        color: #262261;
        left: 15px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); }
      .cms-content ol li:after {
        position: absolute;
        content: url("/images/icons/o-list.svg");
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 40px;
        height: 40px;
        display: block; }
      .cms-content ol li ol {
        padding-left: 30px; }
  .cms-content iframe {
    height: 200px;
    margin-bottom: 32px; }
    @media (min-width: 576px) {
      .cms-content iframe {
        height: 350px; } }
    @media (min-width: 768px) {
      .cms-content iframe {
        height: 36.45833vw; } }
    @media (min-width: 960px) {
      .cms-content iframe {
        height: 26.04167vw; } }
    @media (min-width: 1200px) {
      .cms-content iframe {
        height: 36.45833vw; } }
    @media (min-width: 1400px) {
      .cms-content iframe {
        height: 26.04167vw; } }
  .cms-content img {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 32px;
    height: auto !important; }
    @media (min-width: 1200px) {
      .cms-content img {
        margin-bottom: 1.66667vw; } }
  .cms-content--white {
    color: #fff; }

.cookie-consent {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 40px;
  color: rgba(38, 34, 97, 0.4);
  background: #F9F9F9; }
  .cookie-consent__message {
    flex: 0 0 70%; }
  .cookie-consent__agree {
    flex: 0 0 auto;
    align-self: center; }
    .cookie-consent__agree button {
      display: inline-block;
      width: auto;
      min-width: 200px;
      max-width: 200px;
      font-size: 15px;
      font-weight: 700;
      border: 2px solid #2495d4;
      padding: 16px;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      color: #ffffff;
      background: #2495d4;
      transition: all 0.3s ease-in-out; }
      .cookie-consent__agree button:hover {
        opacity: 0.6; }
      .cookie-consent__agree button:hover {
        color: #ffffff;
        background: #262261;
        border-color: #262261;
        opacity: 1;
        transition: all 0.3s ease-in-out; }
        .cookie-consent__agree button:hover:hover {
          opacity: 0.6; }
        .cookie-consent__agree button:hover:hover {
          opacity: 1; }

.owners {
  margin-top: 48px; }
  .owners__header {
    margin-top: 32px; }
  .owners__list {
    display: flex;
    gap: 64px;
    justify-content: center; }
  .owners .owner {
    margin: 32px 0; }
    .owners .owner img {
      max-width: 120px; }

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

body, html {
  overflow-x: hidden; }

html.frozen {
  overflow: hidden; }

.hidden {
  display: none !important; }

.image-center {
  display: table;
  margin: 0 auto; }

.header {
  font-size: 24px;
  line-height: 140%;
  margin-bottom: 32px;
  font-family: 'Avenir Next LT Pro', sans-serif;
  font-weight: 700;
  color: #262261;
  position: relative; }
  @media (min-width: 960px) {
    .header {
      font-size: 2.5vw;
      margin-bottom: 1.66667vw; } }
  .header--nogutter {
    margin-bottom: 0; }
  .header--white {
    color: #fff; }
  .header--center {
    text-align: center; }
  .header--small {
    font-size: 32px; }
    @media (min-width: 1200px) {
      .header--small {
        font-size: 1.66667vw; } }
  .header--lined:after {
    content: '';
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    left: 0;
    bottom: -15px;
    width: 32px;
    height: 1px; }
    @media (min-width: 1200px) {
      .header--lined:after {
        bottom: -0.78125vw; } }

.article-cover {
  margin-bottom: 49px;
  position: relative; }
  .article-cover__badge {
    position: absolute;
    bottom: 10px;
    left: 10px; }

.article-date {
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 32px;
  color: #cacaca; }

.article-content {
  margin-bottom: 20px; }

.tag {
  margin-bottom: 8px;
  background: #F8F9FA;
  border-radius: 4px;
  font-size: 14px;
  line-height: 120%;
  text-decoration: none;
  color: #2495d4;
  padding: 6px 8px;
  display: inline-block;
  transition: all 0.3s ease-in-out; }
  .tag:hover {
    text-decoration: none;
    background-color: #2495d4;
    color: #fff; }

.application {
  text-align: center;
  border: 1px solid #DBDBDB;
  margin-bottom: 40px;
  padding: 32px; }
  @media (min-width: 1200px) {
    .application {
      margin-bottom: 148px; } }
  .application__icon {
    margin: 0 auto 32px auto;
    display: block; }
  .application__title {
    font-size: 20px;
    line-height: 120%;
    color: #262261;
    margin-bottom: 32px;
    min-height: 72px; }
    .application__title span {
      color: #2495d4;
      font-weight: 700;
      margin-bottom: 16px; }

.footer-person {
  font-size: 20px;
  line-height: 140%;
  margin-bottom: 30px;
  font-weight: 400;
  font-style: normal; }
  .footer-person__avatar-frame {
    width: 119px;
    height: 119px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 16px; }
  .footer-person__name, .footer-person__email, .footer-person__phone {
    margin-bottom: 4px;
    color: #2495d4;
    text-decoration: none; }
  .footer-person__name {
    color: #263228; }
  .footer-person__position {
    color: rgba(38, 34, 97, 0.5); }

.specialist {
  padding: 32px 0;
  border-bottom: 1px solid #E8E8E8; }
  .specialist--reservation {
    border: none;
    padding-top: 0; }
  .specialist__name {
    font-weight: 700;
    font-size: 20px;
    line-height: 120%;
    color: #262261;
    margin-bottom: 8px;
    padding-top: 16px; }
  .specialist__desc {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #262261;
    margin-bottom: 8px; }
  .specialist__label {
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #262261;
    margin-bottom: 16px; }
  .specialist__date {
    font-weight: 700;
    font-size: 18px;
    line-height: 104%;
    color: #262261;
    margin-bottom: 16px;
    display: flex;
    align-items: center; }
    .specialist__date span {
      margin-left: 8px; }
  .specialist__action-btn {
    padding: 16px 19px; }

.flash-message {
  position: fixed;
  top: 16px;
  width: 100%;
  left: 50%;
  z-index: 100;
  margin: 0 auto;
  transform: translateX(-50%);
  padding: 0 16px; }
