.top-menu {
  background: #fff;
  display: flex;
  align-items: center;
  top: 0;
  width: 100%;
  z-index: 2;
  padding: 18px 0; }
  @media (min-width: 768px) {
    .top-menu {
      margin-top: 38px; } }
  .top-menu__right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 16px; }
  .top-menu__logo {
    display: inline-block;
    position: absolute;
    top: -51px;
    z-index: 10;
    width: 90px; }
    @media (min-width: 768px) {
      .top-menu__logo {
        position: relative;
        width: auto;
        top: 0; } }
  .top-menu__icon {
    cursor: pointer;
    transition: all 0.3s ease-in-out; }
    .top-menu__icon:hover {
      opacity: 0.6; }
    .top-menu__icon--search {
      position: relative;
      font-size: 0;
      display: flex;
      align-items: center; }
      .top-menu__icon--search .img-responsive {
        display: inline-block;
        padding: 0 16px; }
      .top-menu__icon--search:before, .top-menu__icon--search:after {
        position: relative;
        width: 1px;
        background: rgba(38, 34, 97, 0.15);
        height: 25px;
        content: '';
        display: inline-block; }
    @media (min-width: 768px) {
      .top-menu__icon--mobile {
        display: none; } }
  .top-menu__action-btn {
    display: none; }
    @media (min-width: 768px) {
      .top-menu__action-btn {
        display: inline-flex; } }

.top-menu-screen {
  position: absolute;
  top: 107px;
  left: 0;
  width: 100%;
  background: #2495d4;
  z-index: 7;
  padding: 30px 0;
  pointer-events: none;
  transform: translateY(-150%);
  transition: all 0.3s ease-in-out; }
  @media (min-width: 768px) {
    .top-menu-screen {
      top: 127px; } }
  @media (min-width: 960px) {
    .top-menu-screen {
      top: 137px; } }
  .top-menu-screen.active {
    pointer-events: visible;
    transform: translateY(0); }
  @media (min-width: 1200px) {
    .top-menu-screen {
      padding: 84px 0 75px 0; } }
  @media (min-width: 1200px) and (max-height: 825px) {
    .top-menu-screen {
      padding: 30px 0; } }
  .top-menu-screen__left {
    align-items: center;
    display: flex; }

.menu-box {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  margin-bottom: 8px; }
  @media (min-width: 1200px) and (max-height: 825px) {
    .menu-box {
      margin-bottom: 8px; } }
  .menu-box__header {
    display: block;
    transition: all 0.3s ease-in-out;
    margin-bottom: 8px;
    color: #fff;
    font-size: 18px;
    font-weight: bold; }
    .menu-box__header:hover {
      opacity: 0.6; }
    @media (max-width: 767px) {
      .menu-box__header {
        font-size: 14px; } }
    .menu-box__header:hover {
      text-decoration: none;
      color: #fff; }
    @media (min-width: 1200px) and (max-height: 825px) {
      .menu-box__header {
        margin-bottom: 8px; } }
  .menu-box__link {
    line-height: 149%;
    font-weight: 400;
    display: block;
    transition: all 0.3s ease-in-out;
    color: #fff;
    margin-bottom: 8px;
    font-size: 15px; }
    .menu-box__link:hover {
      opacity: 0.6; }
    .menu-box__link:hover {
      text-decoration: none;
      color: #fff; }
    @media (min-width: 960px) {
      .menu-box__link {
        font-size: 20px; } }
    @media (min-width: 1200px) and (max-height: 825px) {
      .menu-box__link {
        font-size: 16px;
        margin-bottom: 6px; } }

.search-screen {
  background: #F9F9F9;
  position: absolute;
  top: 107px;
  left: 0;
  width: 100%;
  z-index: 7;
  padding: 30px 0;
  pointer-events: none;
  transform: translateY(-100vh);
  transition: all 0.3s ease-in-out;
  height: 70vh;
  display: flex;
  align-items: center; }
  @media (min-width: 768px) {
    .search-screen {
      top: 127px; } }
  @media (min-width: 960px) {
    .search-screen {
      top: 137px; } }
  @media (min-width: 1200px) {
    .search-screen {
      padding: 84px 0 75px 0; } }
  .search-screen.active {
    pointer-events: visible;
    transform: translateY(0); }
  .search-screen .search-form {
    width: 100%;
    margin-left: 0; }
    .search-screen .search-form__input-wrapper {
      position: relative;
      border: none;
      border-radius: 0; }
    .search-screen .search-form__btn {
      outline: none;
      border: none;
      background: transparent;
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      z-index: 2; }
    .search-screen .search-form__search-input {
      border: none;
      border-bottom: 2px solid #2495d4;
      padding: 16px 16px 16px 84px;
      color: #2495d4;
      font-size: 16px;
      line-height: 140%;
      font-weight: 700;
      outline: none;
      background: transparent;
      display: block;
      width: 100%; }
      @media (min-width: 1200px) {
        .search-screen .search-form__search-input {
          font-size: 32px; } }
      .search-screen .search-form__search-input::-webkit-input-placeholder {
        color: #2495d4;
        opacity: 1; }
      .search-screen .search-form__search-input::-moz-placeholder {
        color: #2495d4;
        opacity: 1; }
      .search-screen .search-form__search-input:-ms-input-placeholder {
        color: #2495d4;
        opacity: 1; }
      .search-screen .search-form__search-input::-ms-input-placeholder {
        color: #2495d4;
        opacity: 1; }
      .search-screen .search-form__search-input::placeholder {
        color: #2495d4;
        opacity: 1; }
