.page-homepage .support {
  padding-top: 40px;
  padding-bottom: 96px; }
  @media (min-width: 1200px) {
    .page-homepage .support {
      padding-top: 96px; } }

.page-homepage .support-centre {
  margin-top: 40px;
  background: linear-gradient(122.99deg, #03A7E8 0%, #1B3A8A 100%);
  padding: 72px 0; }
  .page-homepage .support-centre--together, .page-homepage .support-centre--migration {
    position: relative; }
    .page-homepage .support-centre--together .container, .page-homepage .support-centre--migration .container {
      position: relative;
      z-index: 10; }
      .page-homepage .support-centre--together .container .row, .page-homepage .support-centre--migration .container .row {
        align-items: center;
        justify-content: space-between; }
    .page-homepage .support-centre--together:after, .page-homepage .support-centre--together:before, .page-homepage .support-centre--migration:after, .page-homepage .support-centre--migration:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .page-homepage .support-centre--together:after, .page-homepage .support-centre--migration:after {
      z-index: 4;
      background: rgba(0, 0, 0, 0.12); }
    .page-homepage .support-centre--together:before, .page-homepage .support-centre--migration:before {
      z-index: 5;
      background: transparent; }
  .page-homepage .support-centre--together {
    background: url("/images/bg/program-2.jpg") no-repeat 50% 50%;
    background-size: cover; }
    .page-homepage .support-centre--together:before {
      background: linear-gradient(90deg, rgba(13, 14, 184, 0.9) 0%, rgba(13, 14, 184, 0.5) 50%, rgba(13, 14, 184, 0.239916) 100%); }
  .page-homepage .support-centre--migration {
    background: url("/images/bg/program-2.jpg") no-repeat 50% 50%;
    background-size: cover; }
    .page-homepage .support-centre--migration:before {
      background: linear-gradient(90deg, rgba(13, 14, 184, 0.9) 0%, rgba(13, 14, 184, 0.5) 50%, rgba(13, 14, 184, 0.239916) 100%); }
  @media (min-width: 1200px) {
    .page-homepage .support-centre__header {
      margin-bottom: 64px; } }
  .page-homepage .support-centre__img {
    margin: 0 auto 66px auto;
    width: 70%;
    min-width: 200px; }
  .page-homepage .support-centre__left {
    text-align: center;
    margin-bottom: 32px; }
    @media (min-width: 960px) {
      .page-homepage .support-centre__left {
        margin-bottom: 0; } }
  .page-homepage .support-centre__box {
    margin-bottom: 20px; }
    @media (min-width: 1200px) {
      .page-homepage .support-centre__box {
        margin-bottom: 32px; } }
  .page-homepage .support-centre__menu {
    padding-left: 0;
    list-style-type: none; }
  .page-homepage .support-centre__menu-elem {
    margin-bottom: 16px; }
  .page-homepage .support-centre__menu-link {
    text-decoration: none;
    font-size: 14px;
    line-height: 140%;
    color: #fff;
    transition: all 0.3s ease-in-out;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.3); }
    .page-homepage .support-centre__menu-link:hover {
      opacity: 0.6; }
    @media (min-width: 1200px) {
      .page-homepage .support-centre__menu-link {
        font-size: 22px; } }

.homepage-news {
  padding: 2.5vw 0 0 0; }
