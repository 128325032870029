.slider {
  position: relative; }
  @media (min-width: 768px) {
    .slider {
      display: block; } }
  @media (max-width: 960px) {
    .slider__image-wrapper {
      background-size: cover !important;
      background-position: center center !important;
      background-repeat: no-repeat !important;
      height: 400px;
      width: 100%; } }
  @media (min-width: 960px) {
    .slider__image-wrapper {
      background: none !important; } }
  @media (max-width: 960px) {
    .slider__img {
      display: none !important; } }
  .slider__slide {
    position: relative;
    display: flex;
    overflow: hidden;
    left: -1px; }
  .slider__content {
    z-index: 1;
    position: absolute;
    width: 100%;
    bottom: 15%; }
  .slider__small-title {
    font-size: 13px;
    margin-bottom: 16px;
    color: #fff;
    font-weight: 400; }
    @media (min-width: 1200px) {
      .slider__small-title {
        font-size: 20px; } }
    .slider__small-title span {
      color: #2495d4; }
  .slider__dots-wrapper {
    position: relative; }
  .slider .owl-prev, .slider .owl-next {
    cursor: pointer;
    position: relative;
    width: 48px;
    height: 48px;
    border: none;
    background: transparent;
    outline: none;
    transition: all 0.3s ease-in-out; }
    .slider .owl-prev:hover, .slider .owl-next:hover {
      opacity: 0.6; }
    .slider .owl-prev i, .slider .owl-next i {
      display: none; }
  .slider .owl-prev:after {
    content: url("/images/icons/slide-left.svg"); }
  .slider .owl-next:after {
    content: url("/images/icons/slide-right.svg"); }
  .slider__info {
    color: #fff;
    position: absolute;
    right: calc((100% - 1110px) / 2);
    bottom: 1.82292vw;
    font-weight: 200;
    font-size: 1.04167vw;
    line-height: 1.19792vw;
    letter-spacing: 1.9px;
    z-index: 1; }
    @media (min-width: 1200px) {
      .slider__info {
        display: inline-block; } }

.owl-dot {
  width: 100%;
  height: 2px;
  background: #e5e5e5 !important;
  margin: 2px;
  vertical-align: bottom;
  transition: all 0.2s ease-in-out;
  position: relative;
  cursor: pointer;
  display: none; }
  .owl-dot:hover {
    opacity: 1; }
    .owl-dot:hover span {
      opacity: 1; }
  .owl-dot span {
    position: absolute;
    bottom: 15px;
    left: 50%;
    width: 100%;
    transform: translateX(-50%);
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    letter-spacing: 1px;
    opacity: 0;
    transition: all 0.2s ease-in-out; }
    @media (max-width: 1199px) {
      .owl-dot span {
        font-size: 12px;
        white-space: nowrap; } }
  .owl-dot.active {
    display: block; }
    .owl-dot.active span {
      opacity: 1; }
  .owl-dot:after {
    position: absolute;
    right: 15px;
    opacity: 0;
    bottom: 0;
    transition: all 0.2s linear; }
  .owl-dot.active #active-progress {
    position: absolute;
    left: 0;
    width: 0;
    top: 0;
    height: 100%;
    background: #2495D4;
    transition: all 1s linear; }
  .owl-dot.active:after {
    bottom: 15px;
    opacity: 1; }

.owl-dots {
  position: absolute;
  max-width: 1015px;
  width: 90%;
  bottom: 41px;
  z-index: 2;
  display: none;
  left: calc((100% - 1110px) / 2); }
  @media (min-width: 1200px) {
    .owl-dots {
      display: inline-block; } }

button.owl-dot {
  display: none; }

.slider-wrapper {
  position: relative; }

#carousel-custom-nav {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 320px;
  max-width: 80%;
  bottom: 0;
  z-index: 2;
  padding: 0 2.60417vw;
  margin: 0 auto; }
  @media (min-width: 960px) {
    #carousel-custom-nav {
      position: absolute;
      left: 0.78125vw; } }

@media (min-width: 1200px) {
  #carousel-custom-dots {
    display: block; } }
