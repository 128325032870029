.contact-widget {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%) !important;
  width: 100%;
  max-width: 96px;
  padding: 16px 18px;
  background: #FFFFFF;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
  -webkit-backdrop-filter: blur(30px);
          backdrop-filter: blur(30px);
  border-radius: 10px 0 0 10px;
  z-index: 9999; }
  @media (max-width: 960px) {
    .contact-widget {
      display: none; } }
  .contact-widget__title {
    font-family: Arial;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 149%;
    text-align: center;
    text-transform: uppercase;
    color: #262261; }
  .contact-widget__persons__person {
    display: flex;
    flex-wrap: wrap;
    margin-top: 8px; }
    .contact-widget__persons__person__cover {
      flex: 0 0 100%;
      display: block;
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background-size: cover !important;
      background-position: center !important;
      background-repeat: no-repeat !important; }
    .contact-widget__persons__person__name {
      flex: 0 0 100%;
      margin-top: 8px;
      font-family: Arial;
      font-style: normal;
      font-weight: normal;
      font-size: 13px;
      line-height: 149%;
      text-align: center;
      color: #262261; }
