.article-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 32px; }
  .article-box__link {
    text-decoration: none;
    display: block;
    width: 100%; }
    .article-box__link:hover {
      text-decoration: none; }
  .article-box__badge {
    position: absolute;
    top: 10px;
    left: 10px; }
  .article-box__title {
    text-decoration: none;
    color: #262261;
    font-weight: 700;
    font-size: 20px;
    line-height: 149%;
    display: block;
    margin-bottom: 16px; }
  .article-box__image-wrapper {
    margin-bottom: 32px;
    position: relative;
    display: block; }
    .article-box__image-wrapper:after {
      content: '';
      background: rgba(36, 149, 212, 0.75);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transition: all 0.3s ease-in-out;
      opacity: 0; }
    .article-box__image-wrapper:before {
      content: url("/images/icons/search.svg");
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 0.3s ease-in-out;
      z-index: 1;
      opacity: 0; }
    @media (min-width: 1200px) {
      .article-box__image-wrapper {
        margin-bottom: 1.66667vw; } }
  .article-box__image {
    display: block;
    max-width: 100%;
    width: 100%; }
  .article-box__link:hover .article-box__image-wrapper:after {
    opacity: 1; }
  .article-box__link:hover .article-box__image-wrapper:before {
    opacity: 1; }
  .article-box__date {
    display: flex;
    align-items: center; }
  .article-box__date-content {
    font-size: 16px;
    color: #262261;
    line-height: 120%;
    margin-left: 6px; }
