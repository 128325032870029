.join {
  background: #262261; }
  .join .container-fluid {
    padding: 0 !important; }
  .join__image {
    position: absolute;
    top: -60px;
    max-height: 133%; }
    @media (min-width: 960px) {
      .join__image {
        left: -35px; } }
  .join__right {
    position: relative;
    padding: 40px;
    text-align: center;
    z-index: 10; }
    .join__right:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(13, 67, 97, 0.5);
      z-index: 1; }
    .join__right .header,
    .join__right .cms-content,
    .join__right .btn {
      position: relative;
      z-index: 10; }
    @media (min-width: 768px) {
      .join__right {
        padding: 153px 40px; } }
