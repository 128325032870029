.top-bar {
  background: #fff;
  border-bottom: 1px solid #EBEBEB;
  height: 38px;
  display: flex;
  position: relative;
  top: 0;
  width: 100%;
  z-index: 11;
  align-items: flex-end; }
  @media (min-width: 768px) {
    .top-bar {
      position: fixed; } }
  .top-bar .container, .top-bar .row {
    height: 100%; }
  .top-bar__lang {
    display: inline-block;
    margin-left: 16px; }
  .top-bar__lang-icon {
    height: 20px;
    width: 20px; }
  .top-bar__contact {
    align-items: center;
    display: none; }
    @media (min-width: 768px) {
      .top-bar__contact {
        display: flex; } }
  .top-bar__contact-label {
    color: #262261;
    margin-left: 32px;
    position: relative; }
    .top-bar__contact-label:before {
      top: 50%;
      transform: translateY(-50%);
      height: 16px;
      background: #EBEBEB;
      content: '';
      width: 1px;
      left: -16px;
      position: absolute; }
    .top-bar__contact-label strong {
      display: none; }
      @media (min-width: 1200px) {
        .top-bar__contact-label strong {
          display: block; } }
  .top-bar__contact-link {
    color: #262261;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    white-space: nowrap; }
    .top-bar__contact-link:hover {
      opacity: 0.6; }
  .top-bar__contact-box {
    position: relative;
    margin-left: 16px;
    padding-left: 24px; }
    .top-bar__contact-box:after {
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      position: absolute; }
    .top-bar__contact-box:last-of-type {
      margin-left: 32px; }
      .top-bar__contact-box:last-of-type:before {
        top: 50%;
        transform: translateY(-50%);
        height: 16px;
        background: #EBEBEB;
        content: '';
        width: 1px;
        left: -16px;
        position: absolute; }
    .top-bar__contact-box--phone:after {
      content: url("/images/icons/top-bar-phone.svg"); }
    .top-bar__contact-box--mail:after {
      content: url("/images/icons/top-bar-mail.svg"); }
  .top-bar__socials {
    align-items: center;
    display: none; }
    @media (min-width: 960px) {
      .top-bar__socials {
        display: flex; } }
  .top-bar__social-icon-wrapper {
    transition: all 0.3s ease-in-out;
    display: inline-block;
    margin-left: 14px; }
    .top-bar__social-icon-wrapper:hover {
      opacity: 0.6; }
    .top-bar__social-icon-wrapper:first-of-type {
      margin-left: 19px; }
